import {
  getDatabase,
  ref,
  set,
  //   push,
  child,
  update,
  get,
  //   remove,
} from "firebase/database";

export const initialData = () => {
  const db = getDatabase();
  let squareArray = [
    "#63b598",
    "#ce7d78",
    "#ea9e70",
    "#a48a9e",
    "#c6e1e8",
    "#648177",
    "#0d5ac1",
    "#f205e6",
    "#1c0365",
    "#14a9ad",
    "#4ca2f9",
    "#a4e43f",
    "#d298e2",
    "#6119d0",
    "#d2737d",
    "#c0a43c",
    "#f2510e",
    "#651be6",
    "#79806e",
    "#61da5e",
    "#cd2f00",
    "#9348af",
    "#01ac53",
    "#c5a4fb",
    "#996635",
    "#b11573",
    "#4bb473",
    "#75d89e",
    "#2f3f94",
    "#2f7b99",
    "#da967d",
    "#34891f",
    "#b0d87b",
    "#ca4751",
    "#7e50a8",
    "#c4d647",
    "#63b598",
    "#ce7d78",
    "#ea9e70",
    "#a48a9e",
    "#c6e1e8",
    "#648177",
    "#0d5ac1",
    "#f205e6",
    "#1c0365",
    "#14a9ad",
    "#4ca2f9",
    "#a4e43f",
    "#d298e2",
    "#6119d0",
    "#d2737d",
    "#c0a43c",
    "#f2510e",
    "#651be6",
    "#79806e",
    "#61da5e",
    "#cd2f00",
    "#9348af",
    "#01ac53",
    "#c5a4fb",
    "#996635",
    "#b11573",
    "#4bb473",
    "#75d89e",
    "#2f3f94",
    "#2f7b99",
    "#da967d",
    "#34891f",
    "#b0d87b",
    "#ca4751",
    "#7e50a8",
    "#c4d647",
    "#63b598",
    "#ce7d78",
    "#ea9e70",
    "#a48a9e",
    "#c6e1e8",
    "#648177",
    "#0d5ac1",
    "#f205e6",
    "#1c0365",
    "#14a9ad",
    "#4ca2f9",
    "#a4e43f",
    "#d298e2",
    "#6119d0",
    "#d2737d",
    "#c0a43c",
    "#f2510e",
    "#651be6",
    "#79806e",
    "#61da5e",
    "#cd2f00",
    "#9348af",
    "#01ac53",
    "#c5a4fb",
    "#996635",
    "#b11573",
    "#4bb473",
    "#75d89e",
  ];
  set(ref(db), {
    squareArray,
  });
};

export const getData = () => {
  const dbRef = ref(getDatabase());
  get(child(dbRef, "/")).then((snapshot) => {
    console.log(snapshot.val());
  });
};

export const changeColour = (index: number, colour: string) => {
  console.log("firing");
  const db = getDatabase();
  const dbRef = ref(getDatabase());
  let newArr: any = [];
  get(child(dbRef, "/"))
    .then((snapshot) => {
      if (snapshot.exists()) {
        console.log(snapshot.val());
        newArr = [...snapshot.val().squareArray];
        newArr[index] = colour;
        update(ref(db), {
          squareArray: [...newArr],
        });
      } else {
        console.log("else firing");
      }
    })
    .catch((error) => {
      console.error(error);
    });
};
