import { GridContext } from "./GridContext";
import { useEffect, useState } from "react";
// import { getAuth, User } from "firebase/auth";
import { getDatabase, ref, onValue } from "firebase/database";
// import { FirebaseAuthContext } from "../Auth/FirebaseAuthContext";

// const auth = getAuth();

let defaultColourState = [
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
  "#d1ffef",
];

export const GridProvider: React.FC = ({ children }) => {
  const [grid, setgrid] = useState({
    squareArray: [...defaultColourState],
  });
  useEffect(() => {
    // const todosObject: any = {};
    const db = getDatabase();
    const dbRef = ref(db, "/");
    console.log("This is firing");
    //This gets the todos
    onValue(dbRef, (snapshot) => {
      // console.log("OnVal todos firing");
      if (snapshot.val()) {
        console.log(snapshot.val());
        setgrid(snapshot.val());
      } else {
        console.log("nothing");
      }
    });
  }, []);

  return (
    <GridContext.Provider
      value={{
        grid,
      }}
    >
      {children}
    </GridContext.Provider>
  );
};
