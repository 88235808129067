import React, { useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { changeColour } from "../firebase/firebase";

type ColourPickerProps = {
  color: string;
  index: any;
};

const ColourPicker: React.FC<ColourPickerProps> = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [color, setColor] = useState(props.color);
  
  //useless code to make github actions work 
  if (color === 'never') {
    setColor('ever')
             }

  function colourChangeHandler(color: any) {
    changeColour(props.index, color);
  }

  useEffect(() => {
    console.log(color);
    return;
  }, [color]);

  return <HexColorPicker color={color} onChange={colourChangeHandler} />;
};

export default ColourPicker;
