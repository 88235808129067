import React, { useEffect, useState, useContext } from "react";
import { Box } from "grommet";
// import { initialData, getData } from "../firebase/firebase";
import { GridContext } from "../context/GridContext";
// import { changeColour, initialData } from "../firebase/firebase";

// Test
import ColourPicker from "./colourPicker";
// type Props = {};

const Squaregrid: React.FC = () => {
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const colourGrid = useContext(GridContext);

  //TEST
  const [focus, setfocus] = useState<number | null>(null);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  //Dynamic sizing useEffect
  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  // Sizing for the squares
  let size = "";
  if (screenSize.dynamicWidth >= screenSize.dynamicHeight) {
    size = screenSize.dynamicHeight * 0.95 + "px";
  } else if (screenSize.dynamicHeight > screenSize.dynamicWidth) {
    size = screenSize.dynamicWidth * 0.95 + "px";
  }

  function colourHandler(data: any) {
    console.log(data);
    if (focus === data) {
      setfocus(null);
    } else if (focus !== data && focus !== null) {
      setfocus(null);
    } else {
      setfocus(data);
    }
  }

  const boxFocus = "0 0 0 99999px rgba(0, 0, 0, 0.7)";

  return (
    <Box fill flex justify="center" align="center">
      {/* <button onClick={() => initialData()}>Press</button> */}
      {/* <div style={{ position: "absolute", color: "white", padding: "1rem" }}>
        <p>Width {screenSize.dynamicWidth}</p>
        <p>Height {screenSize.dynamicHeight}</p>
        <p>Size {size}</p>
      </div> */}
      {/* <button onClick={() => console.log(focus)}>Test</button> */}
      {/* <button onClick={() => changeColour(0, "#FFFFF")}>Test</button> */}

      {colourGrid && focus != null ? (
        <Box
          style={{
            position: "absolute",
            zIndex: "9999",
            bottom: focus <= 17 ? "7rem" : "auto",
            top: focus >= 17 ? "7rem" : "auto",
          }}
        >
          <ColourPicker
            color={colourGrid.grid.squareArray[focus]}
            index={focus}
          />
        </Box>
      ) : null}

      <Box
        height={size}
        width={size}
        style={{
          display: "block",
          justifySelf: "center",
        }}
      >
        {colourGrid?.grid.squareArray.map((item: any, index: any) => (
          <Box
            style={{
              display: "inline-block",
              margin: "0rem",
              verticalAlign: "top",
              boxShadow: focus === index ? boxFocus : "",
              zIndex: focus === index ? "9998" : "-1",
              position: focus === index ? "relative" : "static",
            }}
            width="10%"
            height="10%"
            background={item}
            key={index}
            onClick={() => colourHandler(index)}
            focusIndicator={false}
          ></Box>
        ))}
      </Box>
    </Box>
  );
};

export default Squaregrid;
