import { useEffect } from "react";
import { FirebaseContext } from "./FirebaseContext";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAsbCcjF5EMIwZ2TdGU6_QHBWOiUvT-t2s",
  authDomain: "pixels-6d540.firebaseapp.com",
  projectId: "pixels-6d540",
  databaseURL: "https://pixels-6d540-default-rtdb.firebaseio.com/",
  storageBucket: "pixels-6d540.appspot.com",
  messagingSenderId: "512795690738",
  appId: "1:512795690738:web:0da09bdeaa06d47f05b26b",
  measurementId: "G-23FS0HM0HZ",
};

initializeApp(firebaseConfig);

export const FireBaseProvider: React.FC = ({ children }) => {
  useEffect(() => {}, []);

  return (
    <FirebaseContext.Provider value={{}}>{children}</FirebaseContext.Provider>
  );
};
