import React from "react";
// import { createGlobalStyle } from "styled-components";
import { Grommet } from "grommet";
import Squaregrid from "./components/squaregrid";
import "./App.css";

const theme = {
  global: {
    font: {
      family: "Roboto",
      size: "18px",
      height: "20px",
    },
  },
};

function App() {
  return (
    <Grommet full theme={theme}>
      <Squaregrid />
    </Grommet>
  );
}

export default App;
